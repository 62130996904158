<template>
  <default>
    <div class="tw-mb-4 c-blue-text">
      <v-chip-group mandatory active-class=" primary">
        <v-chip active-class="false" :to="{ name: 'Dashboard' }"
          >Dashboard
        </v-chip>
        <span class="mt-2 mx-2"> > </span>
        <v-chip :to="{ name: 'Products' }">Products </v-chip>
        <span class="mt-2 mx-2"> > </span>
        <v-chip :to="{ name: 'AgentGuide' }">
          {{ $options.name }}
        </v-chip>
      </v-chip-group>
    </div>

    <v-card elevation="2" class="tw-px-2 md:tw-px-6 tw-mt-4 md:tw-mt-10">
      <card-title :icon="scanObject(currentProduct, 'icon', '/images/icons/elimu.png')">
        {{ scanObject(currentProduct, 'title') }}
      </card-title>
      <back-nav :to="backNavLink" :page-change="true" />
      <div class="container tw-w-full tw-py-3 md:tw-py-6">
        <p class="md:tw-w-2/3 tw-mx-auto tw-pt-10 tw-pb-6">
          {{ agentInfo }}
        </p>
        <div class="tw-grid md:tw-grid-cols-3 tw-gap-12 tw-my-5">
          <v-card
            v-for="(instruction, index) in instructions"
            :key="index"
            elevation="0"
            class="py-5 px-7 tw-flex tw-flex-col accent2"
          >
            <img
              class="tw-w-45 tw-self-center tw-py-5"
              :src="instruction.image"
              alt="icon"
            />
            <p class="tw-text-center tw-font-semibold primary--text tw-pt-2">
              {{ instruction.details }}
            </p>
          </v-card>
        </div>

        <div class="tw-flex tw-flex-wrap">
          <v-btn
            id="buyCover"
            class="secondary mx-auto tw-my-8"
            :loading="loading"
            @click="loading = true"
            :to="{ name: 'BuyCover' }"
            >Proceed</v-btn
          >
        </div>
      </div>
    </v-card>
  </default>
</template>

<script>
import Default from '../../layouts/Default';
import CardTitle from '../../components/CardTitle';
import clientInfoMixins from '@/mixins/clientInfoMixins';
import BackNav from '@/components/BackNav';

export default {
  name: 'AgentGuide',
  mixins: [clientInfoMixins],
  metaInfo: {
    title: 'Buy Cover - Agent Guide',
  },
  components: {
    CardTitle,
    Default,
    BackNav,
  },
  computed: {
    backNavLink() {
      // eslint-disable-next-line no-bitwise
      return this.scanObject(this.currentProduct, 'title', '').replace(/\s/g, '');
    },
  },
  data() {
    return {
      loading: false,
      agentInfo:
        'As an Agent there are a few things we require from the client that you need to provide  to get them onboarded',
      instructions: [
        {
          image: '/images/icons/client.png',
          details: 'Client details',
        },
        {
          image: '/images/icons/beneficiaries.png',
          details: 'Beneficiary details',
        },
        {
          image: '/images/icons/health.png',
          details: 'Health questions & declaration',
        },
      ],
    };
  },
  created() {
    if (typeof window !== 'undefined' && window.gtag) {
      window.gtag('event', 'agent_guide_page_visited_by_agent', {
        event_category: 'agent guide component created',
        event_label: 'agent guide component created',
      });
    }
  },
  mounted() {
    this.$store.commit('quote/resetSaved', false);
  },
};
</script>
